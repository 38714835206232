@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;1,400&family=Patua+One&display=swap');

:root {
	--dark-purple: #550a50;
	--almost-as-dark-purple: #5d1358;
	--light-lilac: #efe5f7;
	--lighter-purple: #7b3577;
	--middle-violet: #ac5ea6;
	--darker-violet: #914e8d;
	--text-font: 'Barlow', sans-serif;
	--text-font-weight: 300;
	--text-font-size: 20px;
	--nav-h3-font: 'Patua One', cursive;
	--nav-h3-font-size: 30px;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background-color: white;
}

/* ..... background ..... */

.background {
	margin: auto;
	width: 80vw;
	min-height: 90vh;
	background-image: url('./images/petra-klapka-pJ18oxuh090-unsplash.jpg');
	background-size: 100%;
	background-repeat: no-repeat;
}

@media screen and (max-width: 1400px) {
	.background {
		min-height: 65vh;
	}
}

@media screen and (max-width: 1115px) {
	.background {
		background-size: 130%;
		min-height: 65vh;
	}
}

@media screen and (max-width: 820px) {
	.background {
		width: 100vw;
		background-size: 120%;
		min-height: 45vh;
	}
}

@media screen and (max-width: 500px) {
	.background {
		width: 100vw;
		background-size: 160%;
		min-height: 45vh;
	}
}

/* ..... topnav ..... */

header {
	width: 70vw;
	height: 100px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: transparent;
	margin: auto;
}

@media screen and (max-width: 820px) {
	header {
		width: 100vw;
		padding-left: 10vw;
		padding-right: 10vw;
	}
}

@media screen and (max-width: 720px) {
	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 200px;
	}
}

.logo {
	width: 100px;
	padding: 20px;
}

nav {
	display: flex;
	flex-direction: row;
	text-align: center;
	font-family: var(--nav-h3-font);
	font-size: var(--nav-h3-font-size);
}

.about-nav {
	color: white;
	background-color: var(--darker-violet);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px 10px 0 10px;
	width: 130px;
	margin-top: 35px;
	cursor: pointer;
	text-decoration: none;
}

.projects-nav {
	color: white;
	background-color: var(--lighter-purple);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px 10px 0 10px;
	width: 160px;
	margin-top: 35px;
	margin-left: 10px;
	cursor: pointer;
	text-decoration: none;
}

.contact-nav {
	color: white;
	background-color: var(--almost-as-dark-purple);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px 10px 0 10px;
	width: 160px;
	margin-top: 35px;
	margin-left: 10px;
	cursor: pointer;
	text-decoration: none;
}

@media screen and (max-width: 720px) {
	.about-nav,
	.projects-nav,
	.contact-nav {
		width: 110px;
		padding: 10px;
		font-size: 24px;
	}
}

.about-nav.active,
.projects-nav.active,
.contact-nav.active {
	margin-top: 10px;
}

.about-nav:hover,
.projects-nav:hover,
.contact-nav:hover {
	margin-top: 10px;
	transition: 0.5s;
	color: white;
}

/* ..... herobanner ..... */

h1 {
	font-family: 'Patua One', cursive;
	font-weight: 400;
	font-size: 4rem;
	color: var(--darker-violet);
	line-height: 100%;
	padding: 10px;
	width: 80vw;
	text-align: center;
	background-color: white;
	animation-name: herobannerh1;
	animation-duration: 2s;
	animation-iteration-count: 1;
	margin: 0px;
}

@keyframes herobannerh1 {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

h2 {
	font-family: 'Patua One', cursive;
	font-weight: 400;
	font-size: 3rem;
	color: var(--almost-as-dark-purple);
	line-height: 100%;
	padding: 10px;
	width: 80vw;
	text-align: center;
	background-color: white;
	animation-name: herobannerh2;
	animation-duration: 3s;
	animation-iteration-count: 1;
}

@media screen and (max-width: 1115px) {
	h1,
	h2 {
		width: 80vw;
		font-size: 3rem;
	}
}

@media screen and (max-width: 820px) {
	h1,
	h2 {
		font-size: 2.2rem;
		width: 100vw;
	}
}

@keyframes herobannerh2 {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.herobanner-wrapper {
	display: inline-block;
	margin-top: 150px;
}

@media screen and (max-width: 820px) {
	.herobanner-wrapper {
		margin-top: 50px;
	}
}

/* ..... footer ..... */

.linkedin,
.github,
.email {
	margin: 10px;
	cursor: pointer;
}

footer {
	font-family: var(--text-font);
	font-weight: 300;
	color: var(--dark-purple);
	height: 120px;
	width: 80vw;
	margin: auto;
	margin-bottom: 20px;
	text-align: center;
}

.iconswrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	color: var(--dark-purple);
	margin: 30px;
}

.iconswrapper a:visited,
.iconswrapper a:link {
	color: var(--dark-purple);
}

.iconswrapper a:hover,
.iconswrapper a:active {
	color: var(--middle-violet);
}

.unsplash-credit {
	font-size: 12px;
	padding: 20px;
}

/* ..... about me ..... */

.about-me {
	width: 80vw;
	margin: auto;
	min-height: 40vh;
	background-color: var(--light-lilac);
	padding: 5vh 10vh;
}

@media screen and (max-width: 820px) {
	.about-me {
		width: 100vw;
		padding: 30px;
	}
}

p {
	font-family: var(--text-font);
	font-weight: var(--text-font-weight);
	font-size: var(--text-font-size);
	line-height: 150%;
	margin-top: 20px;
	margin-bottom: 20px;
	color: black;
}

/* ..... projects ..... */

.projects-section-wrapper {
	width: 80vw;
	margin: auto;
	background-color: var(--light-lilac);
	padding: 5vh 10vh;
	text-align: center;
}

@media screen and (max-width: 820px) {
	.projects-section-wrapper {
		width: 100vw;
		padding: 30px;
	}
}

.projects-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 600px) {
	.projects-wrapper {
		display: flex;
		flex-direction: column;
	}
}

.project1 {
	width: 300px;
	height: 300px;
	border-radius: 15px;
	background-image: url('./images/NPS_project.jpg');
	background-size: 100%;
	margin: 25px;
	opacity: 0.9;
}

.project1:hover {
	opacity: 1;
}

.projects-wrapper a {
	text-decoration: none;
}

.project2 {
	width: 300px;
	height: 300px;
	background-image: url('./images/movesit_project.jpg');
	background-size: 110%;
	background-position: center;
	border-radius: 15px;
	margin: 25px;
	opacity: 0.9;
}

.project2:hover {
	opacity: 1;
}

@media screen and (max-width: 820px) {
	.project1,
	.project2 {
		width: 200px;
		height: 200px;
	}
}

.projecth4 {
	background-color: white;
	font-family: var(--nav-h3-font);
	font-size: 26px;
	color: var(--dark-purple);
	margin-top: 220px;
	padding: 5px;
}

@media screen and (max-width: 820px) {
	.projecth4 {
		margin-top: 110px;
		font-size: 22px;
	}
}

.project-image {
	display: flex;
	justify-content: center;
	align-items: center;
}

#nps,
#movesit {
	text-align: left;
	background-color: white;
	padding: 30px;
	margin-top: 40px;
	border-radius: 15px;
}

ul {
	font-family: var(--text-font);
	font-weight: var(--text-font-weight);
	font-size: var(--text-font-size);
	color: black;
	list-style-type: circle;
	margin: 20px;
	line-height: 130%;
}

h3 {
	font-family: var(--nav-h3-font);
	font-size: 40px;
	font-weight: 300;
	color: var(--dark-purple);
	text-align: center;
	margin-bottom: 30px;
	padding: 10px;
}

h4 {
	font-family: var(--nav-h3-font);
	font-size: 34px;
	font-weight: 300;
	color: var(--dark-purple);
	margin-bottom: 30px;
	margin-top: 10px;
	text-align: center;
}

h5 {
	font-family: var(--nav-h3-font);
	font-size: var(--nav-h3-font-size);
	font-weight: 300;
	color: var(--dark-purple);
}

a {
	color: black;
	text-decoration: none;
}

.screen_images {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

@media screen and (max-width: 1100px) {
	.screen_images {
		display: flex;
		flex-wrap: wrap;
	}
}

.project_img_hori {
	max-height: 50vh;
	max-width: 70vw;
}

.project_img_vert {
	max-height: 50vh;
	padding: 3px;
}

/* ..... contact ..... */

.contact {
	width: 80vw;
	margin: auto;
	background-color: var(--light-lilac);
	padding: 5vh 10vh;
}

@media screen and (max-width: 820px) {
	.contact {
		width: 100vw;
		padding: 30px;
	}
}

.contact-text p {
	font-size: 26px;
	width: 50vw;
	margin: auto;
	text-align: center;
}

@media screen and (max-width: 820px) {
	.contact-text p {
		width: 60vw;
	}
}

/* ..... Scroll To Top Button ..... */

.totopbutton {
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 10;
	font-size: 40px;
	background-color: transparent;
	border: none;
	color: var(--dark-purple);
	cursor: pointer;
}

.totopbutton:hover {
	color: var(--middle-violet);
}

/* .... link button .... */

.link-wrapper {
	width: 50%;
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.ghlinkbutton {
	font-family: var(--text-font);
	font-size: 18px;
	width: 100%;
	height: 50px;
	border: 1px solid black;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: black;
	background-color: white;
	text-decoration: none;
}

.ghlinkbutton:hover {
	background-color: whitesmoke;
}

@media screen and (max-width: 1100px) {
	.link-wrapper {
		width: 100%;
	}
}

a.textlink {
	color: black;
	font-weight: bold;
	text-decoration: underline;
}

/* .... carousel ...*/

.carousel-container {
	width: 40%;
	margin: auto;
}

@media screen and (max-width: 700px) {
	.carousel-container {
		width: 50%;
	}
}

@media screen and (max-width: 450px) {
	.carousel-container {
		width: 80%;
	}
}
